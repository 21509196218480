<template>
  <v-select
    :items="items"
    v-bind="$attrs"
    v-on="$listeners"
    label="Ação"
    :item-text="getText"
    dense
  >
    <template v-slot:item="{ item }">
      <v-row dense>
        <v-col cols="12">
          <v-card max-width="500" elevation="0" color="transparent">
            <v-card-title class="item-text">
              {{ getText(item) }}
            </v-card-title>
            <v-card-subtitle class="item-text">{{
              item.descricao
            }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>

<script>
import { Acao } from "@/api/eqpdti";
export default {
  name: "AcaoSelect",
  props: ["metaId"],
  data() {
    return {
      items: [],
      lastMetaId: null,
    };
  },
  watch: {
    metaId: function (val) {
      if (val != this.lastMetaId) {
        this.items = [];
        this.$attrs["loading"] = true;
        Acao.list(val).then(({ data }) => {
          this.items = data;
          this.$attrs["loading"] = false;
        });
      }
    },
  },
  methods: {
    getText(item) {
      if (item.uo_sigla) {
        return `${item.uo_sigla} - ${item.acao_id} - ${item.descricao}`;
      }
      return `${item.acao_id} - ${item.descricao}`;
    },
  },
  beforeMount() {
    if (!this.$attrs["item-value"]) {
      this.$attrs["item-value"] = "id";
    }
    if (!this.$attrs["loading"]) {
      this.$attrs["loading"] = true;
    }
  },
  created() {
    this.$attrs["loading"] = true;
    Acao.list().then(({ data }) => {
      this.items = data;
      this.$attrs["loading"] = false;
    });
  },
};
</script>
<style lang="scss">
.item-text {
  font-size: 0.8125rem !important;
}
</style>