<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-text="descricao"
    item-value="id"
    :filter="filter"
    :loading="loading"
    label="Material/Serviço"
    dense
  >
    <template v-slot:item="{ item }">
      <v-row dense>
        <v-col cols="12">
          <v-card max-width="500" elevation="0" color="transparent">
            <v-card-title class="item-text">
              {{ item.nome }} <v-spacer />
              <div v-for="(precoMedioItem, index) in item.precos_medios_item" :key="index">
                {{
                Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(precoMedioItem.preco_medio)
              }}
              </div>
            </v-card-title>
            <v-card-subtitle class="item-text">{{
              item.descricao
            }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { Item } from "@/api/core";
export default {
  name: "ItemAutoComplete",
  data: () => ({
    items: [],
    search: null,
    loading: false,
  }),

  methods: {
    filter(item, queryText) {
      const nome = item.nome.toLowerCase();
      const search = queryText.toLowerCase();
      return nome.indexOf(search) > -1;
    },
  },
  mounted() {
    this.loading = true;
    Item.list().then((response) => {
      this.loading = false;
      this.items = response.data;
    });
  },
};
</script>

<style lang="scss">
.item-text {
  font-size: 0.8125rem !important;
}
</style>
